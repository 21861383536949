import * as React from 'react';
import { list } from './IntrestLinks.module.scss';
import { Container } from 'react-bootstrap';

export const List = (props: any) => {
  return (
    <Container {...props} className={list}>
      {props.children}
    </Container>
  );
};
